import React from "react";
import { TableFilters } from "./filters";
import { Researches } from "./Researches";
import { ResearchProvider } from "./data/ResearchProvider";
import { Pagination } from "./pagination";
import { useResearchesQuery } from "~/src/api/useResearchesQuery";

export const Registry = () => {
  return (
    <ResearchProvider>
      <RegistryContent />
    </ResearchProvider>
  );
};

const RegistryContent = () => {
  const { loading, error, researches, execute } = useResearchesQuery();

  return (
    <div className="p-6 flex flex-col h-screen">
      <TableFilters />
      <Researches loading={loading} error={error} researches={researches} />
      <Pagination update={execute} />
    </div>
  );
};

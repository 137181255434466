import {
  Research,
  ResearchesContext,
  SortField,
  SortOrder,
} from "~/src/features/registry/data";
import { Table } from "~/src/blocks/table/Table";
import { TableRow } from "~/src/blocks/table/TableRow";
import { TableCell } from "~/src/blocks/table/TableCell";
import { TableHeaderCell } from "~/src/blocks/table/TableHeaderCell";
import { Loader } from "~/src/blocks/Loader";
import { FC, useContext } from "react";

const formatDate = (date: string) =>
  date ? new Date(date).toLocaleDateString() : "";

type ResearchesProps = {
  loading: boolean;
  error?: string | null;
  researches: Research[];
};

const headers: Array<{
  align?: "text-center" | "text-left" | "text-right";
  title: string;
  sortField?: SortField;
}> = [
  {
    title: "Номер исследования",
  },
  {
    title: "Модальность",
    align: "text-center",
  },
  {
    title: "Дата исследования",
    align: "text-center",
  },
  {
    title: "Дата загрузки",
    align: "text-center",
    sortField: SortField.loadingDateTime,
  },
  {
    title: "ФИО пациента",
  },
  {
    title: "Номер карты",
  },
  {
    title: "Дата рождения",
    align: "text-center",
  },
  {
    title: "Пол",
    align: "text-center",
  },
];

export const Researches: FC<ResearchesProps> = ({
  loading,
  error,
  researches,
}) => {
  const { setSort, sort } = useContext(ResearchesContext);

  if (error)
    return (
      <div className="text-center py-14 mt-2 opacity-80 text-red-500">
        Произошла ошибка: {error}
      </div>
    );

  const handleSort = (sortField?: SortField) => () => {
    setSort({
      sortField,
      sortOrder:
        sort.sortField === sortField
          ? sort.sortOrder === SortOrder.asc
            ? SortOrder.desc
            : SortOrder.asc
          : SortOrder.desc,
    });
  };

  return (
    <div className="relative my-6 overflow-auto">
      <Loader loading={loading} />
      {!researches.length ? (
        <div className="text-center my-10 text-xl">
          Ничего не найдено, попробуйте изменить параметры запроса
        </div>
      ) : (
        <Table>
          <TableRow className="sticky top-0 bg-gray-750 shadow">
            {headers.map(({ align, sortField, title }, index) => (
              <TableHeaderCell
                key={index}
                align={align}
                sortOrder={sort.sortOrder}
                sortable={sortField !== undefined}
                active={sort.sortField === sortField}
                onClick={
                  sortField !== undefined ? handleSort(sortField) : undefined
                }
              >
                {title}
              </TableHeaderCell>
            ))}
          </TableRow>
          {researches.map((research) => (
            <TableRow key={research.studyInstanceUid}>
              <TableCell>
                <a
                  className="hover"
                  href={`${process.env.REACT_APP_VIEWER_URL}?studyIUid=${research.studyInstanceUid}`}
                  target="_blank"
                  title="Открыть в Dicom Viewer"
                >
                  {research.studyInstanceUid}
                </a>
              </TableCell>
              <TableCell align="text-center">{research.modalities}</TableCell>
              <TableCell align="text-center">
                {formatDate(research.studyDateTime)}
              </TableCell>
              <TableCell align="text-center">
                {formatDate(research.loadingDateTime)}
              </TableCell>
              <TableCell>{research.patientName}</TableCell>
              <TableCell>{research.cardNumber}</TableCell>
              <TableCell align="text-center">
                {formatDate(research.patientBirthDay)}
              </TableCell>
              <TableCell align="text-center">{research.gender}</TableCell>
            </TableRow>
          ))}
        </Table>
      )}
    </div>
  );
};

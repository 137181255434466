import React, { useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "~/src/blocks/form/TextField";
import { Button } from "~/src/blocks/Button";
import { MultiSelect } from "~/src/blocks/form/select/MultiSelect";
import {
  defaultState,
  Filters,
  Modality,
  ResearchesContext,
} from "~/src/features/registry/data";
import isEqual from "lodash/isEqual";

const MODALITIES = [
  ...Object.values(Modality).map((value) => ({
    value,
    name: value,
  })),
];

export const TableFilters = () => {
  const { filters, setFilters, setPageNumber } = useContext(ResearchesContext);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isDirty, isSubmitting },
  } = useForm<Filters>({
    defaultValues: filters,
  });

  // Форма имеет начальное состояние
  const isDefault = useMemo(
    () => isEqual(defaultState.filters, getValues()),
    [isSubmitting, filters]
  );

  const onSumbit = handleSubmit((data) => {
    setFilters({
      ...filters,
      ...data,
    });
    setPageNumber(defaultState.pagination.pageNumber);
    reset(getValues());
  });

  const onReset = () => {
    reset(defaultState.filters);
    setPageNumber(defaultState.pagination.pageNumber);
    setFilters(defaultState.filters);
  };

  return (
    <div className="flex justify-end">
      <form
        onSubmit={onSumbit}
        className="max-w-[1200px] grid grid-cols-[3fr,repeat(4,1fr)] gap-3 items-center"
      >
        <TextField
          placeholder="Номер исследования"
          inputProps={{
            ...register("studyIUidSearch"),
          }}
        />
        <Controller
          name="modalitySearch"
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              className="min-w-[12rem] [&+div]:min-w-[12rem]"
              fullWidth
              value={field.value}
              options={MODALITIES}
              onChange={(e, value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        <TextField
          placeholder="ФИО пациента"
          inputProps={{
            ...register("patientNameSearch"),
          }}
        />
        <TextField
          placeholder="Номер карты"
          inputProps={{
            ...register("cardNumberSearch"),
          }}
        />
        <div className="flex gap-2">
          <Button disabled={!isDirty} className="m-0 w-full" type="primary">
            Применить
          </Button>
          {!isDefault && (
            <Button className="m-0 w-full" onClick={onReset}>
              Сбросить
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

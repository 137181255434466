import { useEffect, useContext } from "react";
import { Research, ResearchesContext } from "../features/registry/data";
import useAxios from "axios-hooks";

type GetResearchesResponse = {
  studyInfoDtos: Research[];
  pageParameters: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
};

export const useResearchesQuery = () => {
  const {
    pagination,
    filters,
    setTotalPages,
    setTotalItems,
    error,
    researches,
    setResearches,
    setError,
    sort,
  } = useContext(ResearchesContext);
  const filtersParams = Object.fromEntries(
    Object.entries(filters).filter(([key, value]) => Boolean(value))
  );
  const [{ data, loading, error: axiosError }, execute] =
    useAxios<GetResearchesResponse>({
      url: "/studies",
      params: {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        // TODO: разобраться, что ждет бэк для поля modalitySearch при множественном выборе
        ...filtersParams,
        ...sort,
      },
    });

  useEffect(() => {
    if (!loading) {
      if (axiosError) {
        console.log("error", axiosError);
        setError(axiosError.toString());
      } else {
        setResearches(data?.studyInfoDtos || []);
        setTotalPages(data?.pageParameters.totalPages || 0);
        setTotalItems(data?.pageParameters.totalItems || 0);
      }
    }
  }, [data, loading, axiosError]);

  return {
    loading,
    error,
    researches,
    execute,
  };
};

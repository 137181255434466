import Axios from "axios";
import { configure } from "axios-hooks";
import qs from "qs";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API,
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(params, {
        arrayFormat: "repeat",
        skipNulls: true,
      }),
  },
});

function initApi() {
  configure({
    axios,
    defaultOptions: {
      useCache: false,
    },
  });
}

export { initApi };

import React, { FC } from "react";
import Input, {
  InputUnstyledProps,
  InputUnstyledInputSlotProps,
} from "@mui/base/InputUnstyled";
import cn from "classnames";

export const TextField: FC<
  InputUnstyledProps & {
    inputProps: Partial<InputUnstyledInputSlotProps>;
    fullWidth?: boolean;
  }
> = ({ inputProps, fullWidth, ...rest }) => {
  return (
    <Input
      className=""
      slotProps={{
        input: {
          className: cn(
            "text-ellipsis w-full p-3 bg-gray-650 rounded placeholder:text-gray-500 border border-1 border-gray-650 focus:border focus:border-1 focus:border-slate-500 hover:border hover:border-1 hover:border-gray-600 text-gray-400",
            {
              "w-full": fullWidth,
            }
          ),
          ...inputProps,
        },
      }}
      {...rest}
    />
  );
};

import React from "react";
import { Registry } from "./features/registry";
import { initApi } from "~/src/api";

initApi();

function App() {
  return (
    <div className="h-screen">
      <Registry />
    </div>
  );
}

export default App;

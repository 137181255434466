import { FC } from "react";

export const Loader: FC<{
  loading?: boolean;
}> = ({ loading }) =>
  loading ? (
    <div className="absolute inset-0 backdrop-blur flex items-center justify-center">
      Загрузка...
    </div>
  ) : null;

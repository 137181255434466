import React, { FC, ReactNode } from "react";
import cn from "classnames";

export const Table: FC<{
  children: ReactNode;
  className?: string;
}> = ({ className, children }) => {
  return (
    <table className={cn('w-full', className)}>
      <tbody>{children}</tbody>
    </table>
  );
};

import { FC, ReactNode } from "react";
import cn from "classnames";

export const TableRow: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <tr className={cn("border-b-[1px] border-gray-650", className)}>
      {children}
    </tr>
  );
};

import React from "react";

export type Research = {
  studyInstanceUid: string;
  modalities: Modality[];
  studyDateTime: string;
  loadingDateTime: string;
  patientName: string;
  cardNumber: string;
  patientBirthDay: string;
  gender?: string | null;
};

export enum Modality {
  CT = "CT",
  MG = "MG",
  DX = "DX",
  CR = "CR",
  RF = "RF",
  SR = "SR",
}

export type Filters = {
  studyIUidSearch?: string;
  modalitySearch?: string[];
  patientNameSearch?: string;
  cardNumberSearch?: string;
};

export enum SortOrder {
  desc,
  asc
}

export enum SortField {
  loadingDateTime,
}

export type SortFields = {
  sortField?: SortField;
  sortOrder?: SortOrder;
}

export type ResearchContextType = {
  researches: Research[];
  error?: string | null;
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
  filters: Filters;
  sort: SortFields;
  // actions
  setResearches: (researches: Research[]) => void;
  setError: (error: string | null) => void;
  setFilters: (filters: Partial<Filters>) => void;
  setTotalPages: (total: number) => void;
  setTotalItems: (total: number) => void;
  setPageSize: (size: number) => void;
  setPageNumber: (page: number) => void;
  setSort: (sort: SortFields) => void;
};
export const defaultState: ResearchContextType = {
  researches: [],
  error: null,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  },
  filters: {
    modalitySearch: [],
    cardNumberSearch: "",
    patientNameSearch: "",
    studyIUidSearch: "",
  },
  sort: {
    sortField: SortField.loadingDateTime,
    sortOrder: SortOrder.desc,
  },
  setResearches: () => {},
  setError: () => {},
  setFilters: () => {},
  setPageNumber: () => {},
  setPageSize: () => {},
  setTotalItems: () => {},
  setTotalPages: () => {},
  setSort: () => {},
};
export const ResearchesContext =
  React.createContext<ResearchContextType>(defaultState);

import React, { FC, useContext } from "react";
import { ResearchesContext } from "../data";
import { Button } from "~/src/blocks/Button";
import { usePagination } from "@mantine/hooks";
import { Select, SelectProps } from "~/src/blocks/form/select";
import { RefreshIcon } from "~/src/icons";

const ROWS_PER_PAGE = [5, 10, 25].map((x) => ({
  value: x,
  name: `${x}`,
}));

type PaginationProps = {
  update: () => void;
};

export const Pagination: FC<PaginationProps> = ({ update }) => {
  const {
    pagination: { pageNumber, pageSize, totalItems, totalPages },
    setPageNumber,
    setPageSize,
  } = useContext(ResearchesContext);

  const { range } = usePagination({ page: pageNumber, total: totalPages });

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleChangePageSize: SelectProps["onChange"] = (_, value) => {
    setPageSize(value as number);
    setPageNumber(1);
  };

  return (
    <div className="flex justify-between items-center gap-6 mt-auto">
      <div className="flex items-center text-gray-500">
        <div>
          <span>Всего исследований: </span>
          <strong className="text-gray-400">{totalItems}</strong>
          <span className="mx-2">|</span>
        </div>
        <span>Элементов на странице:</span>
        <Select
          className="ml-2 min-w-[4.5rem] [&+div]:min-w-[4.5rem]"
          options={ROWS_PER_PAGE}
          value={pageSize}
          onChange={handleChangePageSize}
        />
        <div className="text-right">
          <Button onClick={update} className="flex">
            <RefreshIcon className="w-[24px] mr-2" />
            Обновить
          </Button>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Button disabled={pageNumber <= 1} onClick={handlePrevPage}>
          {"<"}
        </Button>
        {range.map((pageButton, index) =>
          pageButton === "dots" ? (
            <span className="self-end mb-2 mx-2" key={index}>
              ...
            </span>
          ) : (
            <Button
              key={index}
              type={pageNumber === pageButton ? "primary" : "default"}
              onClick={() => setPageNumber(pageButton)}
            >
              {pageButton}
            </Button>
          )
        )}
        <Button disabled={pageNumber === totalPages} onClick={handleNextPage}>
          {">"}
        </Button>
      </div>
    </div>
  );
};

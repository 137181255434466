import React, { FC, ReactNode } from "react";
import { SortOrder } from "~/src/features/registry/data";
import { ArrowIcon } from "~/src/icons";
import cn from "classnames";

export const TableHeaderCell: FC<{
  children?: ReactNode;
  align?: "text-center" | "text-left" | "text-right";
  sortable?: boolean;
  sortOrder?: SortOrder;
  active?: boolean;
  onClick?: () => void;
}> = ({
  children,
  align = "text-left",
  sortable,
  sortOrder,
  onClick,
  active,
}) => {
  return (
    <th
      className={cn("select-none text-gray-600 font-normal px-2 py-4 relative", align, {
        "cursor-pointer": sortable,
        "hover:text-gray-500": sortable,
        "text-gray-500": active,
      })}
      onClick={onClick}
    >
      <span className={cn({
        "[&>svg]:opacity-0": !active
      })}>
        {children}
        {sortable ? (
          <ArrowIcon
            className={cn("ml-1 inline-block w-5 shrink-0", {
              "rotate-180": sortOrder === SortOrder.desc,
              "hover: opacity-50": !active,
            })}
          />
        ) : null}
      </span>
    </th>
  );
};

import { FC } from "react";
import cn from "classnames";

type ButtonProps = {
  type?: "primary" | "default";
  disabled?: boolean;
  children: React.ReactNode | string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | undefined) => void;
};

const styles = {
  default:
    "border-gray-600 bg-gray-750 hover:text-gray-200 active:text-white hover:bg-gray-700 active:bg-gray-600",
  primary:
    "border-primary-100 bg-primary-100 text-white hover:bg-blue-600 active:bg-blue-500",
};

export const Button: FC<ButtonProps> = ({
  children,
  type = "default",
  disabled,
  className,
  onClick,
}) => {
  const typeStyles = styles[type];

  return (
    <button
      className={cn(
        "m-2 py-3 px-5 rounded border",
        typeStyles,
        "disabled:bg-gray-750 disabled:text-gray-700 disabled:border-gray-700",
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

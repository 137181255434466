import React, { FC } from "react";
import { SelectUnstyledProps } from "@mui/base/SelectUnstyled";
import { Divider } from "~/src/blocks/Divider";
import { CustomSelect, CustomOption, Value } from "./components";

export type SelectProps = SelectUnstyledProps<Value> & {
  options: { value: number | string; name: string }[];
  value: number | string | null | undefined;
  fullWidth?: boolean;
};

export const Select: FC<SelectProps> = ({ options, value, ...rest }) => {
  return (
    <CustomSelect {...rest} value={value}>
      {options.map((option, i) => (
        <React.Fragment key={option.value}>
          <CustomOption value={option.value} active={option.value === value}>
            {option.name}
          </CustomOption>
          {/*{i < options.length - 1 && <Divider />}*/}
        </React.Fragment>
      ))}
    </CustomSelect>
  );
};

import React, { FC } from "react";
import { MultiSelectUnstyledProps } from "@mui/base/MultiSelectUnstyled";
import { CustomOption, CustomMultiSelect, Value } from "./components";
import { CheckedIcon } from "~/src/icons";

export type MultiSelectProps = MultiSelectUnstyledProps<Value> & {
  options: { value: Value; name: string }[];
  fullWidth?: boolean;
};

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  value,
  ...rest
}) => {
  return (
    <CustomMultiSelect
      {...rest}
      value={value}
      renderValue={(opts) =>
        opts
          .map((x) => options.find((opt) => opt.value === x.value)?.name)
          .join(", ")
      }
    >
      {options.map((option, i) => {
        const checked = value?.includes(option.value);
        return (
          <React.Fragment key={option.value}>
            <CustomOption value={option.value} active={checked}>
              {option.name}
              {checked ? <CheckedIcon className="w-4" /> : null}
            </CustomOption>
            {/*{i < options.length - 1 && <Divider />}*/}
          </React.Fragment>
        );
      })}
    </CustomMultiSelect>
  );
};

import { defaultState, ResearchesContext } from "./index";
import { ReactNode, useState, FC } from "react";

export const ResearchProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  // Исследования
  const [researches, setResearches] = useState(defaultState.researches);
  // Ошибки
  const [error, setError] = useState(defaultState.error);
  // Пагинация
  const [rowsPerPage, setRowsPerPage] = useState(
    defaultState.pagination.pageSize
  );
  const [page, setPage] = useState(defaultState.pagination.pageNumber);
  const [totalPages, setTotalPages] = useState(
    defaultState.pagination.totalPages
  );
  const [totalItems, setTotalItems] = useState(
    defaultState.pagination.totalItems
  );
  // Фильтры
  const [filters, setFilters] = useState(defaultState.filters);
  // Сортировка
  const [sort, setSort] = useState(defaultState.sort);

  return (
    <ResearchesContext.Provider
      value={{
        researches,
        setResearches,
        error,
        pagination: {
          pageNumber: page,
          pageSize: rowsPerPage,
          totalItems,
          totalPages,
        },
        filters,
        sort,
        // actions
        setPageNumber: setPage,
        setError,
        setFilters,
        setPageSize: setRowsPerPage,
        setTotalItems,
        setTotalPages,
        setSort,
      }}
    >
      <>{children}</>
    </ResearchesContext.Provider>
  );
};

import React, { forwardRef } from "react";
import SelectUnstyled, { SelectUnstyledProps } from "@mui/base/SelectUnstyled";
import { SelectUnstyledRootSlotProps } from "@mui/base/SelectUnstyled";
import PopperUnstyled, { PopperUnstyledProps } from "@mui/base/PopperUnstyled";
import OptionUnstyled, { OptionUnstyledProps } from "@mui/base/OptionUnstyled";
import { ArrowIcon } from "~/src/icons";
import cn from "classnames";
import MultiSelectUnstyled, {
  MultiSelectUnstyledProps,
} from "@mui/base/MultiSelectUnstyled";

export type Value = string | number;

const Button = React.forwardRef(function Button(
  props: SelectUnstyledRootSlotProps<Value> & {
    fullWidth: boolean;
    placeholder?: string;
  },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props;
  const noSelected = !other.children;

  return (
    <button
      type="button"
      {...other}
      className={cn(
        `flex justify-between items-center gap-2 text-left p-3 bg-gray-650 text-gray-400 rounded border border-1 border-gray-650 hover:border-1 hover:border-gray-600 ${other.className}`,
        {
          "w-full": props.fullWidth,
          "text-gray-500": noSelected,
        }
      )}
      ref={ref}
    >
      <div className="truncate">{other.children || other.placeholder || "Не выбрано"}</div>
      <ArrowIcon
        className={cn("w-4 shrink-0", {
          "rotate-180": !ownerState.open,
        })}
      />
    </button>
  );
});

const StyledListbox = React.forwardRef<any, any>((props, ref) => (
  <ul
    ref={ref}
    {...props}
    className={`overflow-auto border-none bg-gray-650 text-left rounded-b border-t border-gray-750 ${props.className}`}
  />
));

const StyledPopper = React.forwardRef<HTMLDivElement, PopperUnstyledProps>(
  function ADR(props, ref) {
    return (
      <PopperUnstyled
        ref={ref}
        {...props}
        // open
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 1],
            },
          },
        ]}
        className={`z-40 bg-gray-650 rounded-b ${props.className}`}
      />
    );
  }
);

export const CustomSelect = React.forwardRef(function CustomSelect(
  props: SelectUnstyledProps<Value>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: Button,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled ref={ref} {...props} slots={slots} />;
});

export const CustomMultiSelect = React.forwardRef(function CustomSelect(
  props: MultiSelectUnstyledProps<Value>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: Button,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <MultiSelectUnstyled ref={ref} {...props} slots={slots} />;
});

export const CustomOption = forwardRef(
  <TValue extends {}>(
    props: OptionUnstyledProps<TValue> & { active?: boolean },
    ref: React.ForwardedRef<HTMLLIElement>
  ) => {
    return (
      <OptionUnstyled
        {...props}
        ref={ref}
        className={cn(
          "flex justify-between cursor-pointer hover:bg-gray-600 hover:text-white px-4 py-2",
          {
            "bg-gray-700": props.active,
            "text-white": props.active,
          }
        )}
      />
    );
  }
);

import { FC, ReactNode } from "react";

export const TableCell: FC<{
  children?: ReactNode;
  align?: 'text-center' | 'text-left' | 'text-right'
}> = ({ children, align = 'text-left' }) => {
  return (
    <td className={`px-2 py-4 ${align}`}>
      {children}
    </td>
  );
};
